import Vue from 'vue';
import VueI18n from 'vue-i18n';

// element-ui built-in lang
import elementEnLocale from 'element-ui/lib/locale/lang/en';
import elementThLocale from 'element-ui/lib/locale/lang/th';

// User defined lang
import enLocale from './en';
import thLocale from './th';

Vue.use(VueI18n);

const messages = {
    en: {
        ...enLocale,
        ...elementEnLocale
    },
    th: {
        ...thLocale,
        ...elementThLocale
    }
    // 这里如果有其它语言包继续按照规则添加即可
};

export const getLocal = () => {
    return localStorage.getItem('locale') || 'th';
};

const i18n = new VueI18n({
    locale: getLocal(),
    messages
});

export default i18n;
