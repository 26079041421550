


























import { Component, Vue } from 'vue-property-decorator';
import { trackEvent } from '@/api/home';
import Clipboard from 'clipboard';

@Component({
    name: 'landing-page-web'
})
export default class extends Vue {
    dialogVisible = false

    copyLink: string = 'https://www.gogohelpers.com/en/login'

    mounted() {
        if (process.env.NODE_ENV != 'production') {
            this.copyLink = 'http://gogohelpers.test.sesameitech.com/en/login';
        }
    }

    handleApply() {
        this.dialogVisible = true;
    }

    clickWhatsApp() {
        window.open('https://wa.me/639171847828')
        trackEvent({
            eventType: 304,
            url: window.location.href,
            websiteName: 'PH',
            tool: 'PC',
            btnName: 'Contact Our Recruiter',
            btnTargetValue: '639171847828'
        })
    }

    lickClick() {
        window.open(this.copyLink, '_blank')
    }

    copy() {
        let clipboard = new Clipboard('.copy');
        clipboard.on('success', (e)=> {
            this.$message.success('copy successfully');
            e.clearSelection();
            this.dialogVisible = false;
            clipboard.destroy();
        });
    }
}
